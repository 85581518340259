import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { UtilsService } from '@core/services/utils.service';
import { DesignLifespan } from '@asset/enums/design-lifespan';
import { ASSET_ENDPOINT } from '@asset/tokens/asset-endpoint.token';
import {
  AssetModel,
  AssetUploadModel,
  AssetImportableModel,
} from '@asset/models/asset.model';
import {
  Config as HttpRequestConfiguration,
  HttpService,
} from '@core/services/http.service';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AssetService {
  constructor(
    private readonly httpService: HttpService,
    private readonly utilsService: UtilsService,
    @Inject(ASSET_ENDPOINT) private readonly assetEndpoint: string,
    private readonly http: HttpClient,
  ) {}

  createAsset(
    asset: AssetUploadModel &
      (Record<'site_ref_id', string> | Record<'building_ref_id', string>),
  ) {
    asset = this.utilsService.convertObjectPropsToApiDateFormat(asset, [
      'expected_construction_date',
    ]);
    asset.design_lifespan = Number(asset.design_lifespan) as DesignLifespan;
    return this.http
      .put<AssetModel>(this.assetEndpoint, asset, { observe: 'response' })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  updateAsset(assetId: number, asset: Partial<AssetUploadModel>) {
    return this.http
      .patch<AssetModel>(
        `${this.assetEndpoint}/${assetId}`,
        this.utilsService.convertObjectPropsToApiDateFormat(asset, [
          'expected_construction_date',
        ]),
        { observe: 'response' },
      )
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.redirectForbidden(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  getAsset(id: number, config?: HttpRequestConfiguration) {
    return this.http
      .get<AssetModel>(`${this.assetEndpoint}/${id}`, { observe: 'response' })
      .pipe(
        this.httpService.openSystemNotification(config),
        this.httpService.performAction(config),
        this.httpService.catchAssetError(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  getImportableAsset(ddb_ref_id: string) {
    return this.http
      .get<AssetImportableModel>(`${this.assetEndpoint}/ddb/${ddb_ref_id}`, {
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        this.httpService.catchAssetError(),
        this.httpService.body(),
        this.httpService.trackError(),
      );
  }

  searchAssets(projectNumber: string) {
    return this.http
      .get<{
        existing_assets: Pick<AssetModel, 'id' | 'name'>[];
        assets_to_import: Pick<AssetModel, 'ddb_ref_id' | 'name'>[];
      }>(this.assetEndpoint, {
        params: new HttpParams().set('project_number', projectNumber),
        observe: 'response',
      })
      .pipe(
        this.httpService.openSystemNotification(),
        this.httpService.performAction(),
        map((result) => {
          return result.status === HttpStatusCode.NoContent
            ? { existing_assets: [], assets_to_import: [] }
            : result.body ?? { existing_assets: [], assets_to_import: [] };
        }),
        this.httpService.trackError(),
      );
  }
}
